// components/Content.js
import React from "react";
import AcquisitionFlag from "./AcquisitionFlag";
import "../App.css";

const Content = () => {
  return (
    <div className="content">
      <div className="text-container">
        <AcquisitionFlag />
        <h1>Kairos</h1>
        <p>
          We've all been there - hunting through Slack threads, scanning docs,
          and clicking through endless tabs just to find that one piece of
          information. The more our teams create and share, the harder it
          becomes to find what we need, when we need it.
        </p>

        <p>
          That's why we built Kairos - a search engine that works as fast as you
          think. Connect once to your workspace, and instantly find anything
          you're looking for, right when you need it. No more context switching,
          no more interrupting colleagues.
        </p>

        <p>
          No buzzwords, no complexity. Just ridiculously fast search that helps
          you stay in your flow.
        </p>
      </div>
    </div>
  );
};

export default Content;
