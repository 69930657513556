// components/AcquisitionFlag.js
import React from "react";

const AcquisitionFlag = () => {
  return (
    <div className="inline-flag">
      We've been acquired! Stay tuned for more updates.
    </div>
  );
};

export default AcquisitionFlag;
